*{
  margin: 0px;
  padding: 0px;
}

.App {
  text-align: center;
}
:root{
  --main-ft: Helvetica, Arial, sans-serif;
  --display-ft: 'Satisfy', cursive; 
  --condensed-ft: 'Saira Extra Condensed', sans-serif;
  --scnd-color: #FF7A64;
  --thrd-color: rgba(112, 88, 126, 0.7);
  --light: rgb(242, 242, 247);
  --dark-text: rgb(207, 207, 230);
  --dark: #211b21;
}
/* Nav Bar------------------------------------------------------------ */
.nav-buttons{
  display: flex;
  justify-content: center;
}
.NavBar{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  font-family: var(--condensed-ft);
}
.home-identifier{
  text-align: left;
  font-family: 'Satisfy', cursive;
  font-size: 200%;
  line-height: 30px;
}
.nav{
  all: unset;
  margin: 0px 25px;
  font-size: 155%;
  font-weight: 400;
}
.nav:hover{
  color: var(--scnd-color);
  border-bottom: 1px solid var(--scnd-color)
}
.theme{
  max-width: 30px;
  max-height: 30px;
  margin-left: 90%;
}
.theme:hover{
  border-color: var(--scnd-color);
}

/* Themes------------------------------------------------------------ */

.antd-divider {
font-family: var(--condensed-ft);
font-size: 150% !important ;
}

.dark{
  color: var( --dark-text)!important ;
  background-color: var( --dark) !important;
  transition: .5s;
}
.light{
  color: var( --dark) !important;
  background-color: var( --light)!important;
  transition: .5s;
}

.dark-divider {
  color: var( --dark-text) !important;
  border-top: var( --scnd-color) !important;
}

.light-divider {
  color: var( --dark) !important;
  border-top: var( --dark) !important;
}

/* ___________________________________________________ */

@media only screen and (max-width: 750px) {
  .NavBar{
    grid-template-columns: 1fr;
  }
  .home-identifier{
    text-align: center;
    font-family: 'Satisfy', cursive;
    font-size: 150%;
  }
  .theme{
    /* margin-left: 45%; */
    margin-top: 10px;
    justify-self: center;
  }
  .nav{
    all: unset;
    margin: 0px 10px;
    font-size: 100%;
  }
}