.landingPage{
    scroll-snap-type: x;
}

.land-hero{
    border-radius: 0.5%;
    object-fit: cover;
    height: auto;
    width: 85vw;
    object-position: 0 20%;
}

.project-container{
    padding-bottom: 5%;
    width: 100%;    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
}

.card{
    padding: 10px;
    margin: 5px; 
    transition: .7s;
    height: 40vh;
    width: 30vw;
    padding: 25px 5px;
    &:hover{
        scale: 105%;
        transition: .7s; 
        animation-name: float;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        border: solid 5px var(--scnd-color);
    }
}

.card-img{
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: solid var(--light) 5px;
    border-radius: 2%;
    align-self: center;
}

.card-title{
    font-size: 1.5em;
    font-family: var( --condensed-ft);
    margin: 0% 5%;
    line-height: 35px;
}

.card-left{
    text-align: left;
}
.card-link{
    text-decoration: none;
    &:hover{
        border-color: var(--scnd-color) !important;
        color: var(--scnd-color) !important;
    } 
}
.card-link-primary{
    color: var(--light) !important;
    background-color: var(--scnd-color);
    &:hover{
        border-color: var(--scnd-color) !important;
        color: var(--scnd-color) !important;
    } 
}

p{
    font-family: var(--main-ft);
}

.card-link:hover{
    color: var(--pop) !important;
}

.dark-card{
    color: var( --dark) !important;
    background-color: var( --light);

  }
.dark-card img {
    border: solid var(--dark) 5px;
    border-radius: 2%;
}
.light-card{
    color: var( --light) !important;
    background-color: var( --dark);
  }
  /* --------------------------------------------------- */

  @keyframes float {
    from  {transform: translate(0px, 0px) }
    to {transform: translate(0px, 10px) }
  }

  /* @media only screen and (max-width: 1200px) {
    .card{
    }
    } */
    @media only screen and (max-width: 1000px) {
        .card{
            width: 85vw;
        }
        }

  @media only screen and (max-width: 750px) {
    .land-hero{
        max-width: 100vw !important;
        height: auto
    }
    .card-title{
        font-size: 1.5em;
        font-family: var( --condensed-ft);
    }
    .card{
        grid-template-columns: 1fr;
        overflow: hidden;
        border-radius: 0% 5%;
        font-size: .8em;
    }
  }
