.AboutPage{
    padding: 0px 0px 50px 0px;
    /* height: 100vh; */

}
.about-section{
    padding: 0px 10%;
}

.about-content p{
    padding-bottom: 10px;
}

.about-section img{
    width: 40%;
    height: 40%;
    object-fit: contain;
    padding: 0% 3% 1% 0%;
    float: left; 
    border-radius: 20% 0%;
    transform: translateY(-50px);
}
.about-section p{
    text-align: left; 
}
.about-title{
    font-family: var(--display-ft);
    font-size: 250%;
    padding-bottom: 2px;
    margin: 0px 35% 3% 35%; 
    border-radius: 5%;
    padding-top: 30px;
}
.about-content{
    line-height: 25px;
    font-family: Helvetica, sans-serif;
}
.about-divider{
    padding: 20px 0px;
}
.skills-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 10%;
    font-family: var(--main-ft);
}

.about-button{
    float: left;
    margin: 0px 10px 15px 10px;
    padding: 5px;
    &:hover{
        transition: 0s !important;
        background-color: var(--scnd-color) !important;
        color: var(--light) !important;
        border-color: var(--scnd-color) !important;
    }
}

.dark-button{
    background-color: var(--dark);
    color: var(--light);
}
.light-button{
    background-color: var(--light);
    color: var(--scnd-color);
}


.contact-blurb{
    font-family: var(--main-ft);
    margin: 10px 20% 10px 20%
}
.skill-object{
    display: flex;
    padding: 0px 0px 15px 0px;
}

.skill-object p{
    padding-left: 5px;
}
.social-icon{
    color: var(--scnd-color); 
    font-size: 2em;
    padding: 0px 20px;
}
.view-resume {
    display: flex;
    justify-content: left;
}
.resume{
    padding: 20px 50px 10px 10px
}
.coding-img{
    width: 25%;
    height: auto;
    padding-bottom: 2%;
}
/* ----------------------------------------------------- */

@media only screen and (max-width: 750px) {
    .skills-container{
        grid-template-columns: 1fr 1fr;
        padding-left: 15%;
    }
    .view-resume {
        display: flex;
        justify-content: center;
    }
    .about-title{
        font-size: 200%;
        margin: 0px 20% 3% 20%; 
    }
  }
  @media only screen and (max-width: 1375px) {
    .view-resume {
        display: flex;
        justify-content: center;
    }
    .about-section img{
        width: 100%;
        height: 100%;
        transition: .5s;
    }
  }