.ArtPage p{
    font-family: var(--main-ft);
    padding-bottom: 2%;
}

.spin-container{
    height: 90vh;
}

.art-title{
    font-family: var(--display-ft);
    font-size: 250%;
    margin: 0px 35% 3% 35%;
    border-radius: 5%;
    padding-top: 30px;
}

.art-content{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 15% 10% 15%;
    justify-content: center;
}

.artwork:hover{
    scale: 120%;
    border-radius: 10px;
    transition: .5s;
}

/* _____________________ */

@media only screen and (max-width: 750px) {
    .art-title{
        font-size: 200%;
        margin: 0px 20% 3% 20%; 
    }
  }